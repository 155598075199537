import React, { useEffect, useState, useRef } from "react";
import { Helmet } from 'react-helmet'
import "../App.css"
import $ from "jquery"
import { useTranslation } from 'react-i18next';
import { Elements, CardElement } from '@stripe/react-stripe-js';
import StripeCardElementGrabber from "../Components/StripeCardElementGrabber"
import {loadStripe} from '@stripe/stripe-js';
import CircleLoader from "../Components/CircleLoader"
import stripeBadge from '../partner.png';
import ternaryBadge from '../ternary-badge.png';
import bootbox from "bootbox";
import "bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import currencies from "../Locales/currencies";
import { current } from "immer";

const Donate = () => {
  // Define variables
  const { t, i18n } = useTranslation();
  // Page data
  const [data, setData] = useState({scripts: [], color: "", secondary_color: "", name: "", logo: "", pk_key: ""})
  const [dataLoaded, setDataLoaded] = useState(false)
  const [stripePromise, setStripePromise] = useState();

  // Form elements
  const [email, setEmail] = useState("")
  const [cardErrors, setCardErrors] = useState("")
  const [submit, setSubmit] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [amount, setAmount] = useState()
  const [currency, setCurrency] = useState("USD")
  const [message, setMessage] = useState()
  const [idempotent, setIdempotent] = useState("")

  let grabberRef = useRef();

  // On page load event
  useEffect(() => {
    var url = `/donationInfo`;
    // load data and set data loaded to true
    fetch(url).then((res) => res.json())
      .then((res) => {
        if (res.success) {
          if (!res.enable_donations) {
            window.location.href = "/";
          }
          setData(res)
        } else {
          window.location.href = "/";
        }
      }).catch((err) => {
        return bootbox.alert(err.toString());
      })
  }, [])


  // On data loaded event
  useEffect(() => {
    if (data.pk_key !== "") {

        var stripePromiseVar = loadStripe(data.pk_key);
        setStripePromise(stripePromiseVar);
        setIdempotent(uuidv4());
        setDataLoaded(true);
    }
  }, [data])



  // Randomly generate UUIDV4 for request
  const uuidv4 = () =>  {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  


  // On buttons / Input events

  // Purchase form submitting
  const handleDonateSubmit = () => {
    if (!submit) {
      setSubmit(true);
      donateSubmit();
    }
  }


  const donateSubmit = () => {
    if (!buttonDisabled) {
        setButtonDisabled(true);
        var formData = {
          email: email,
          fname: firstName,
          lname: lastName,
          message: message,
          currency: currency,
          amount: amount
        }
        grabberRef.current.getToken().then((result) => {
            if (result.error) {
            // Inform the user if there was an error
            setButtonDisabled(false);
            setSubmit(false);
            setCardErrors(result.error.message);
            } else {
            // Send the token to your server
            stripeTokenHandler(formData, result.token);
            }
        }).catch((err) => {
            setButtonDisabled(false);
            setSubmit(false);
            return bootbox.alert(err.message);
        })


    }
    
  }


  // Stripe token created
  const stripeTokenHandler = (formData, token) => {
    // Submit the form
    // clear card errors
    setCardErrors("")
    formData.token = token.id;
    formData.ip = token.client_ip;

    if (formData.email && formData.fname && formData.lname && formData.message && formData.currency && formData.amount) {
      // Submit form
      fetch("/api/donate", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Idempotency-Key': idempotent
        },
        body: JSON.stringify(formData)
      }).then((val) => val.json())
        .then((val) => {
          if (val.success) {
            return bootbox.alert("Thank you for making your donation!", () => {
              window.location.reload();
            });
          } else {
            setButtonDisabled(false);
            setSubmit(false);
            return bootbox.alert(t(val.error));
          }

        }).catch((err) => {
          setButtonDisabled(false);
          setSubmit(false);
          return bootbox.alert(t("Failed request ") + err.toString());
        })
    } else {
      console.log(formData);
      setButtonDisabled(false);
      setSubmit(false);
      bootbox.alert(t("Please fill in all fields"));
    }
  }

  // Render methods & front end
  return (
    <div>
      {dataLoaded === true ?
        <div>
          <Helmet>
          <title>Donate - {data.name}</title>
            <style>
            {`
                  :root {
                  --main-color: ${data.color};
                  --secondary-color: ${data.secondary_color};
                  }
                  html, body {
                    background-color: ${data.color} !important;
                  }
                  .hidden {
                     display: none !important;
                   }`
            }
            </style>


            {data.scripts.map(script => (
              script === 'https://cdn.usefathom.com/script.js' && data.name === 'PlayTyme' ? (
                <script data-site="CSEASAAW" src={script} ></script>
              )
              : (
                <script src={script} ></script>
              )
            ))}

          </Helmet>
          <form id="donate-form" onSubmit={(e) => { e.preventDefault(); handleDonateSubmit() }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1>Donate to {data.name}</h1>

                  <div style={{ marginTop: -15, marginBottom:15,textAlign:"center" }} className="row logo-row">
                    <div className="col-12">
                      <img className={"logo"} src={data.logo} />
                    </div>
                  </div>

                  <div style={{marginTop:0, marbinBottom:0}} className="row">
                    <div style={{marginTop:0, marbinBottom:0}} className="col-sm-6">
                      <input id="fname" className="input-field billing-field" name="firstname" type="text" placeholder={t("First Name")} required value={firstName} onChange={e => setFirstName(e.target.value)} />
                    </div>
                    <div style={{marginTop:0, marbinBottom:0}} className="col-sm-6">
                      <input id="lname" className="input-field billing-field" name="lastname" type="text" placeholder={t("Last Name")} required value={lastName} onChange={e => setLastName(e.target.value)} />
                    </div>
                  </div>

                  <input id="email" className="input-field" name="email" type="email" placeholder="Email" required value={email} onChange={e => setEmail(e.target.value)} />
                  
                  <input value={amount} onChange={(event) => {setAmount(parseFloat(event.target.value))}} min="1" placeholder="Donation Amount" step=".01" type="number" name="amount" className="form-control" required />
                  <select value={currency} onChange={(event) => {setCurrency(event.target.value)}} name="currency"  className="form-control select" required>
                        <option value="USD" label="US dollar">USD</option>
                        <option value="CAD" label="Canadian dollar">CAD</option>
                        <option value="EUR" label="Euro">EUR</option>
                        <option value="JPY" label="Japanese yen">JPY</option>
                        <option value="GBP" label="Pound sterling">GBP</option>
                        <option value="AUD" label="Australian dollar">AUD</option>
                        <option disabled>──────────</option>
                        <option value="AED" label="United Arab Emirates dirham">AED</option>
                        <option value="AFN" label="Afghan afghani">AFN</option>
                        <option value="ALL" label="Albanian lek">ALL</option>
                        <option value="AMD" label="Armenian dram">AMD</option>
                        <option value="ANG" label="Netherlands Antillean guilder">ANG</option>
                        <option value="AOA" label="Angolan kwanza">AOA</option>
                        <option value="ARS" label="Argentine peso">ARS</option>
                        <option value="AWG" label="Aruban florin">AWG</option>
                        <option value="AZN" label="Azerbaijani manat">AZN</option>
                        <option value="BAM" label="Bosnia and Herzegovina convertible mark">BAM</option>
                        <option value="BBD" label="Barbadian dollar">BBD</option>
                        <option value="BDT" label="Bangladeshi taka">BDT</option>
                        <option value="BGN" label="Bulgarian lev">BGN</option>
                        <option value="BHD" label="Bahraini dinar">BHD</option>
                        <option value="BIF" label="Burundian franc">BIF</option>
                        <option value="BMD" label="Bermudian dollar">BMD</option>
                        <option value="BND" label="Brunei dollar">BND</option>
                        <option value="BOB" label="Bolivian boliviano">BOB</option>
                        <option value="BRL" label="Brazilian real">BRL</option>
                        <option value="BSD" label="Bahamian dollar">BSD</option>
                        <option value="BTN" label="Bhutanese ngultrum">BTN</option>
                        <option value="BWP" label="Botswana pula">BWP</option>
                        <option value="BYN" label="Belarusian ruble">BYN</option>
                        <option value="BZD" label="Belize dollar">BZD</option>
                        <option value="CDF" label="Congolese franc">CDF</option>
                        <option value="CHF" label="Swiss franc">CHF</option>
                        <option value="CLP" label="Chilean peso">CLP</option>
                        <option value="CNY" label="Chinese yuan">CNY</option>
                        <option value="COP" label="Colombian peso">COP</option>
                        <option value="CRC" label="Costa Rican colón">CRC</option>
                        <option value="CUC" label="Cuban convertible peso">CUC</option>
                        <option value="CUP" label="Cuban peso">CUP</option>
                        <option value="CVE" label="Cape Verdean escudo">CVE</option>
                        <option value="CZK" label="Czech koruna">CZK</option>
                        <option value="DJF" label="Djiboutian franc">DJF</option>
                        <option value="DKK" label="Danish krone">DKK</option>
                        <option value="DOP" label="Dominican peso">DOP</option>
                        <option value="DZD" label="Algerian dinar">DZD</option>
                        <option value="EGP" label="Egyptian pound">EGP</option>
                        <option value="ERN" label="Eritrean nakfa">ERN</option>
                        <option value="ETB" label="Ethiopian birr">ETB</option>
                        <option value="EUR" label="EURO">EUR</option>
                        <option value="FJD" label="Fijian dollar">FJD</option>
                        <option value="FKP" label="Falkland Islands pound">FKP</option>
                        <option value="GBP" label="British pound">GBP</option>
                        <option value="GEL" label="Georgian lari">GEL</option>
                        <option value="GGP" label="Guernsey pound">GGP</option>
                        <option value="GHS" label="Ghanaian cedi">GHS</option>
                        <option value="GIP" label="Gibraltar pound">GIP</option>
                        <option value="GMD" label="Gambian dalasi">GMD</option>
                        <option value="GNF" label="Guinean franc">GNF</option>
                        <option value="GTQ" label="Guatemalan quetzal">GTQ</option>
                        <option value="GYD" label="Guyanese dollar">GYD</option>
                        <option value="HKD" label="Hong Kong dollar">HKD</option>
                        <option value="HNL" label="Honduran lempira">HNL</option>
                        <option value="HKD" label="Hong Kong dollar">HKD</option>
                        <option value="HRK" label="Croatian kuna">HRK</option>
                        <option value="HTG" label="Haitian gourde">HTG</option>
                        <option value="HUF" label="Hungarian forint">HUF</option>
                        <option value="IDR" label="Indonesian rupiah">IDR</option>
                        <option value="ILS" label="Israeli new shekel">ILS</option>
                        <option value="IMP" label="Manx pound">IMP</option>
                        <option value="INR" label="Indian rupee">INR</option>
                        <option value="IQD" label="Iraqi dinar">IQD</option>
                        <option value="IRR" label="Iranian rial">IRR</option>
                        <option value="ISK" label="Icelandic króna">ISK</option>
                        <option value="JEP" label="Jersey pound">JEP</option>
                        <option value="JMD" label="Jamaican dollar">JMD</option>
                        <option value="JOD" label="Jordanian dinar">JOD</option>
                        <option value="JPY" label="Japanese yen">JPY</option>
                        <option value="KES" label="Kenyan shilling">KES</option>
                        <option value="KGS" label="Kyrgyzstani som">KGS</option>
                        <option value="KHR" label="Cambodian riel">KHR</option>
                        <option value="KID" label="Kiribati dollar">KID</option>
                        <option value="KMF" label="Comorian franc">KMF</option>
                        <option value="KPW" label="North Korean won">KPW</option>
                        <option value="KRW" label="South Korean won">KRW</option>
                        <option value="KWD" label="Kuwaiti dinar">KWD</option>
                        <option value="KYD" label="Cayman Islands dollar">KYD</option>
                        <option value="KZT" label="Kazakhstani tenge">KZT</option>
                        <option value="LAK" label="Lao kip">LAK</option>
                        <option value="LBP" label="Lebanese pound">LBP</option>
                        <option value="LKR" label="Sri Lankan rupee">LKR</option>
                        <option value="LRD" label="Liberian dollar">LRD</option>
                        <option value="LSL" label="Lesotho loti">LSL</option>
                        <option value="LYD" label="Libyan dinar">LYD</option>
                        <option value="MAD" label="Moroccan dirham">MAD</option>
                        <option value="MDL" label="Moldovan leu">MDL</option>
                        <option value="MGA" label="Malagasy ariary">MGA</option>
                        <option value="MKD" label="Macedonian denar">MKD</option>
                        <option value="MMK" label="Burmese kyat">MMK</option>
                        <option value="MNT" label="Mongolian tögrög">MNT</option>
                        <option value="MOP" label="Macanese pataca">MOP</option>
                        <option value="MRU" label="Mauritanian ouguiya">MRU</option>
                        <option value="MUR" label="Mauritian rupee">MUR</option>
                        <option value="MVR" label="Maldivian rufiyaa">MVR</option>
                        <option value="MWK" label="Malawian kwacha">MWK</option>
                        <option value="MXN" label="Mexican peso">MXN</option>
                        <option value="MYR" label="Malaysian ringgit">MYR</option>
                        <option value="MZN" label="Mozambican metical">MZN</option>
                        <option value="NAD" label="Namibian dollar">NAD</option>
                        <option value="NGN" label="Nigerian naira">NGN</option>
                        <option value="NIO" label="Nicaraguan córdoba">NIO</option>
                        <option value="NOK" label="Norwegian krone">NOK</option>
                        <option value="NPR" label="Nepalese rupee">NPR</option>
                        <option value="NZD" label="New Zealand dollar">NZD</option>
                        <option value="OMR" label="Omani rial">OMR</option>
                        <option value="PAB" label="Panamanian balboa">PAB</option>
                        <option value="PEN" label="Peruvian sol">PEN</option>
                        <option value="PGK" label="Papua New Guinean kina">PGK</option>
                        <option value="PHP" label="Philippine peso">PHP</option>
                        <option value="PKR" label="Pakistani rupee">PKR</option>
                        <option value="PLN" label="Polish złoty">PLN</option>
                        <option value="PRB" label="Transnistrian ruble">PRB</option>
                        <option value="PYG" label="Paraguayan guaraní">PYG</option>
                        <option value="QAR" label="Qatari riyal">QAR</option>
                        <option value="RON" label="Romanian leu">RON</option>
                        <option value="RON" label="Romanian leu">RON</option>
                        <option value="RSD" label="Serbian dinar">RSD</option>
                        <option value="RUB" label="Russian ruble">RUB</option>
                        <option value="RWF" label="Rwandan franc">RWF</option>
                        <option value="SAR" label="Saudi riyal">SAR</option>
                        <option value="SEK" label="Swedish krona">SEK</option>
                        <option value="SGD" label="Singapore dollar">SGD</option>
                        <option value="SHP" label="Saint Helena pound">SHP</option>
                        <option value="SLL" label="Sierra Leonean leone">SLL</option>
                        <option value="SLS" label="Somaliland shilling">SLS</option>
                        <option value="SOS" label="Somali shilling">SOS</option>
                        <option value="SRD" label="Surinamese dollar">SRD</option>
                        <option value="SSP" label="South Sudanese pound">SSP</option>
                        <option value="STN" label="São Tomé and Príncipe dobra">STN</option>
                        <option value="SYP" label="Syrian pound">SYP</option>
                        <option value="SZL" label="Swazi lilangeni">SZL</option>
                        <option value="THB" label="Thai baht">THB</option>
                        <option value="TJS" label="Tajikistani somoni">TJS</option>
                        <option value="TMT" label="Turkmenistan manat">TMT</option>
                        <option value="TND" label="Tunisian dinar">TND</option>
                        <option value="TOP" label="Tongan paʻanga">TOP</option>
                        <option value="TRY" label="Turkish lira">TRY</option>
                        <option value="TTD" label="Trinidad and Tobago dollar">TTD</option>
                        <option value="TVD" label="Tuvaluan dollar">TVD</option>
                        <option value="TWD" label="New Taiwan dollar">TWD</option>
                        <option value="TZS" label="Tanzanian shilling">TZS</option>
                        <option value="UAH" label="Ukrainian hryvnia">UAH</option>
                        <option value="UGX" label="Ugandan shilling">UGX</option>
                        <option value="USD" label="United States dollar">USD</option>
                        <option value="UYU" label="Uruguayan peso">UYU</option>
                        <option value="UZS" label="Uzbekistani soʻm">UZS</option>
                        <option value="VES" label="Venezuelan bolívar soberano">VES</option>
                        <option value="VND" label="Vietnamese đồng">VND</option>
                        <option value="VUV" label="Vanuatu vatu">VUV</option>
                        <option value="WST" label="Samoan tālā">WST</option>
                        <option value="XAF" label="Central African CFA franc">XAF</option>
                        <option value="XCD" label="Eastern Caribbean dollar">XCD</option>
                        <option value="XOF" label="West African CFA franc">XOF</option>
                        <option value="XPF" label="CFP franc">XPF</option>
                        <option value="ZAR" label="South African rand">ZAR</option>
                        <option value="ZMW" label="Zambian kwacha">ZMW</option>
                        <option value="ZWB" label="Zimbabwean bonds">ZWB</option>
                    </select>
                  <textarea required className="input-field" placeholder="Message" name="message" value={message}  onChange={(event) => {setMessage(event.target.value)}}></textarea>

                  {dataLoaded === true ? 
                    <div id="card-element">
                        <Elements stripe={stripePromise}>
                            <CardElement
                              id="cardElement"
                              options={{
                                style: {
                                  base: {
                                    color: '#32325d',
                                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                                    fontSmoothing: 'antialiased',
                                    fontSize: '16px',
                                    '::placeholder': {
                                      color: '#aab7c4'
                                    }
                                  },
                                  invalid: {
                                    color: '#fa755a',
                                    iconColor: '#fa755a'
                                  }
                                },
                              }}
                            />
                            <StripeCardElementGrabber ref={grabberRef} />
                          </Elements>
                    </div>
                  : null}
                  <div id="card-errors" role="alert">{cardErrors}</div>
                  <input disabled={buttonDisabled} style={{opacity: buttonDisabled ? 0.7 : 1, cursor: buttonDisabled ? "no-drop" : "pointer"}} className="input-field" id="checkout-submit" type="submit" value={buttonDisabled ? "..." : t("Donate")} />
                  <div style={{display:'flex',justifyContent:'center'}}>
                    <a className="badge"  target="_blank" href="https://stripe.com/partners/ternary-developers"><img  style={{marginTop:30,width:120}} src={stripeBadge}/></a>
                  </div>
                </div>

              </div>
            </div>

          </form>
          <div style={{textAlign:"center",width:'100%', marginTop:-80, height:30}}>
            <a target="_blank" href="https://ternarydev.com/" className="watermark">Powered by <span style={{fontWeight:700}}>Ternary</span></a>
          </div>
        </div> :
        <div>
          <div id="navbar">
          </div>

          <div>
            <CircleLoader />
          </div>

        </div>
      }
    </div>)
}

export default Donate;
