import bootbox from "bootbox";
import $ from "jquery";

export const cancel = (subscriptionId, cancelAtPeriod, setButtonDisabled, pullData, t) => {
  var status = "reactivate";
  if (cancelAtPeriod) {
    status = "cancel";
  }
  bootbox.confirm({
    message: t(`Are you sure you want to ${status} your subscription?`),
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-success'
      },
      cancel: {
        label: 'No',
        className: 'btn-danger'
      }
    },
    callback: function(result) {
      if (result == true) {
        if (status == "cancel") {

          bootbox.confirm(
            `
              <h5 class="modal-title">${t("Reason for Cancellation?")}</h5>

              <form class="bootbox-form" id="cancel-form">
                <select id="cancel-reason" class="bootbox-input bootbox-input-select form-control">
                  <option value="Price">${t("Too expensive")}</option>
                  <option value="Competitor">${t("Joining another group")}</option>
                  <option value="Time">${t("No time to use")}</option>
                  <option value="Other">${t("Other")}</option>
                </select>
                <textarea id="cancel-feedback" required placeholder="${t("Enter your feedback here.")}" class="bootbox-input bootbox-input-textarea form-control"></textarea>
              </form>`, 
            function(result) {
              if(result != null) {
                let reason = $("#cancel-reason").val();
                let feedback = $("#cancel-feedback").val();
                console.log(reason);
                console.log(feedback);
                if (!reason || !feedback) {
                  bootbox.alert("Please provide some feedback to cancel");
                } else {
                    submitCancel(subscriptionId, cancelAtPeriod, reason, feedback, setButtonDisabled, pullData, t);
                }
              }
          });
        } else {
          submitCancel(subscriptionId, cancelAtPeriod, null, null, setButtonDisabled, pullData, t);
        }
      }
    }
  })
}



export const cancelAuthroizeNet = (subscriptionId, setButtonDisabled, pullData, t) => {
  bootbox.confirm({
    message: t(`Are you sure you want to cancel your subscription?`),
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-success'
      },
      cancel: {
        label: 'No',
        className: 'btn-danger'
      }
    },
    callback: function(result) {
      if (result == true) {

          bootbox.confirm(
            `
              <h5 class="modal-title">${t("Reason for Cancellation?")}</h5>

              <form class="bootbox-form" id="cancel-form">
                <select id="cancel-reason" class="bootbox-input bootbox-input-select form-control">
                  <option value="Price">${t("Too expensive")}</option>
                  <option value="Competitor">${t("Joining another group")}</option>
                  <option value="Time">${t("No time to use")}</option>
                  <option value="Other">${t("Other")}</option>
                </select>
                <textarea id="cancel-feedback" required placeholder="${t("Enter your feedback here.")}" class="bootbox-input bootbox-input-textarea form-control"></textarea>
              </form>`, 
            function(result) {
              if(result != null) {
                let reason = $("#cancel-reason").val();
                let feedback = $("#cancel-feedback").val();
                console.log(reason);
                console.log(feedback);
                if (!reason || !feedback) {
                  bootbox.alert("Please provide some feedback to cancel");
                } else {
                    submitCancelAuthorizeNet(subscriptionId, reason, feedback, setButtonDisabled, pullData, t);
                }
              }
          });
        
      }
    }
  })
}

export const submitCancelAuthorizeNet = (subscriptionId, reason, feedback, setButtonDisabled, pullData, t) => {
  setButtonDisabled(true);
  // Submit form
  const options = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      reason: reason,
      feedback: feedback
    })
  }
  fetch(`/api/authorizeNet/cancel/${subscriptionId}`, options)
    .then(val => val.json())
    .then((val) => {
      setButtonDisabled(false);
      if (val.success) {
        bootbox.alert(t(`Successfully Updated Subscription`), () => {
          pullData();
        });
      } else {
        bootbox.alert(t(val.error));
      }
    }).catch((err) => {
      setButtonDisabled(false);
      bootbox.alert(err.toString());
    })
}

export const submitCancel = (subscriptionId, cancelAtPeriod, reason, feedback, setButtonDisabled, pullData, t) => {
  setButtonDisabled(true);
  // Submit form
  const options = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      cancelAtPeriod: cancelAtPeriod,
      reason: reason,
      feedback: feedback
    })
  }
  fetch(`/api/user/cancel/${subscriptionId}`, options)
    .then(val => val.json())
    .then((val) => {
      setButtonDisabled(false);
      if (val.success) {
        bootbox.alert(t(`Successfully Updated Subscription`), () => {
          pullData();
        });
      } else {
        bootbox.alert(t(val.error));
      }
    }).catch((err) => {
      setButtonDisabled(false);
      bootbox.alert(err.toString());
    })
}


export const upgrade = (subscriptionId, plan, idempotent, setButtonDisabled, pullData, t) => {

  bootbox.prompt({
    title: t("Coupon code"),
    buttons: {
      confirm: {
        label: t('Submit Upgrade'),
        className: 'btn-success'
      },
      cancel: {
        label: t('Cancel Upgrade'),
        className: 'btn-danger'
      }
    },
    callback: (result) => {
      if (result != null) {
        setButtonDisabled(true);

        var coupon = false;
        if (result != "") {
          coupon = result;
        }

        bootbox.confirm({
          message: `${t("Are you sure you want to upgrade your current plan to")} ${plan.name}?`,
          buttons: {
            confirm: {
              label: t('Upgrade'),
              className: 'btn-success'
            },
            cancel: {
              label: t('Cancel'),
              className: 'btn-danger'
            }
          },
          callback: function(result) {
            if (result == true) {
              let options = {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json',
                  'Idempotency-Key': idempotent
                },
                body: JSON.stringify({
                  plan: plan.planId,
                  coupon: coupon
                })
              }
              fetch(`/api/user/${subscriptionId}/plan/update`, options).then((val) => val.json())
                .then((val) => {
                  setButtonDisabled(false);
                  if (val.success) {
                    bootbox.alert(t("Successfully Updated Plan"), () => {
                      pullData();
                    });
                  } else {
                    bootbox.alert(t(val.error));
                  }
                }).catch((err) => {
                  setButtonDisabled(false);
                  bootbox.alert(err.toString());
                })
    
            }
          }
        })
      }
    }
  })


}




export const addon = (plan, setButtonDisabled, pullData, t) => {
  bootbox.confirm({
    message: `${t("Are you sure you want to add")} ${plan.name}?`,
    buttons: {
      confirm: {
        label: t('Add'),
        className: 'btn-success'
      },
      cancel: {
        label: t('Cancel'),
        className: 'btn-danger'
      }
    },
    callback: function(result) {
      if (result == true) {

        bootbox.prompt({
          title: t("Coupon code"),
          buttons: {
            confirm: {
              label: t('Submit Add-on'),
              className: 'btn-success'
            },
            cancel: {
              label: t('Cancel Add-on'),
              className: 'btn-danger'
            }
          },
          callback: (result) => {
            if (result != null) {
              setButtonDisabled(true);

              var coupon = false;
              if (result != "") {
                coupon = result;
              }

              let options = {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  coupon: coupon
                })
              }
              fetch(`/api/user/plan/${plan.planId}`, options).then((val) => val.json())
                .then((val) => {
                  setButtonDisabled(false);
                  if (val.success) {
                    bootbox.alert(t("Successfully Added Plan"), () => {
                      pullData();
                    });
                  } else {
                    bootbox.alert(t(val.error));
                  }
                }).catch((err) => {
                  setButtonDisabled(false);
                  bootbox.alert(err.toString());
                })
            }
          }
        })
      }
    }
  })
}
