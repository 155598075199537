import bootbox from "bootbox";


export const deleteCard = (card, setCardModal, pullData, t) => {
  if (card) {
    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        cardId: card
      })
    }
    setCardModal(false);
    fetch("/api/user/card/delete", options).then((res) => res.json())
      .then((res) => {
        bootbox.alert(t("Successfully deleted card"));
        pullData();
      }).catch((err) => {
        bootbox.alert(err.toString());
      })
  } else {
    bootbox.alert(t("Please select a card"));
  }
}


export const saveCard = (card, setCardModal, pullData, t) => {
  if (card) {
    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        cardId: card
      })
    }
    setCardModal(false);
    fetch("/api/user/card", options).then((res) => res.json())
      .then((res) => {
        bootbox.alert(t("Successfully saved card as default"));
        pullData();
      }).catch((err) => {
        bootbox.alert(err.toString());
      })
  } else {
    bootbox.alert(t("Please select a card"));
  }
}

export const addCard = (grabberRef, setCardModal, setAddNewCard, pullData, t) => {
  grabberRef.current.getToken().then((result) => {
    if (result.error) {
      bootbox.alert(result.error.message);
    } else {
      stripeTokenHandler(result.token, setCardModal, setAddNewCard, pullData, t)
    }
  }).catch((err) => {
    bootbox.alert(err.message);
  })
}

export const stripeTokenHandler = (token, setCardModal, setAddNewCard, pullData, t) => {
  const options = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      token: token.id
    })
  }
  setCardModal(false);
  fetch("/api/user/card/add", options)
    .then(val => val.json())
    .then((val) => {
      if (val.success) {
        bootbox.alert(t("Successfully Added Card"));
        setAddNewCard(false);
        pullData();
      } else {
        bootbox.alert(t(val.error));
      }
    }).catch((err) => {
      bootbox.alert(err.toString());
    })
}
