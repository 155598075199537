import $ from "jquery";

// Pull all releases from API
export const getPosts = (setPosts, setCatalogue, setLoading) => {
    $.get("/api/user/posts",
        (data, status) => {
            if (status == "success") {
                if (data.success == true) {
                    setLoading(true);
                    setPosts(data.posts);
                    setCatalogue(data.catalogue);
                    return;
                } else {
                    return window.location.href = "/dashboard";
                }
            } else {
                return window.location.href = "/dashboard";
            }
        });
}
