import React, { useEffect, useState, useRef } from "react";

const Catalogue = ({ data, useCatalogue, setUseCatalogue, setActiveCatalogue }) => {    
    return (
        // onClick={() => {setCatalogue(data.)}} 
        <div style={{ borderColor: useCatalogue === data._id ? "var(--main-color)" : "#EBEDF5" }} 
        onClick={() => {
            if (useCatalogue === data._id) {
                setUseCatalogue("");
                setActiveCatalogue([]);
            } else {
                setUseCatalogue(data._id); setActiveCatalogue(data.posts);
            }
        }} className="catalogue card">
            {/* <img className="card-img" src={"https://i.imgur.com/XQxpdOx.png"} /> */}
            {/* <div className="card-img-overlay"> */}
                <h5 className="card-title">{data.title}</h5>
                <p className="card-text">{data.posts.length} post(s)</p>
            {/* </div> */}
        </div>
    )
}

export default Catalogue
