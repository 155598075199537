import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Dashboard from "./Screens/Default"
import Order from "./Screens/Order"
import Register from "./Screens/Register"
import Donate from "./Screens/Donate"
import bootbox from "bootbox";
var $ = require('jquery');
global.jQuery = require("jquery");
window.$ = $;
// var bootstrap = require('bootstrap');
// var bootbox = require('bootbox');
const App = () => {
  return (
    <Router>
      <div>
        {/* <nav>
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/order">Order</Link>
            </li>
            <li>
              <Link to="/register">Register</Link>
            </li>
          </ul>
        </nav> */}

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/order/:key">
            <Order />
          </Route>
          <Route path="/order">
            <Order />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/donate">
            <Donate />
          </Route>
          <Route path="/">
            <Register />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}



export default App;
