import React, { useEffect, useState, useRef } from "react";
import './LoggedOut.css';

const LoggedOut = ({ name, logo, highlightFeatures, free, products, price, coupon, discount, total, redirect}) => {    
    console.log(products);
    const [productName, setProductName] = useState(products[0].name);
    const [interval, setInterval] = useState(products[0].interval);
    const [intervalCount, setIntervalCount] = useState(products[0].intervalCount);
    const [productPrice, setPrice] = useState(products[0].price);
    // const [total, setTotal] = useState(products[0].price);


    return (
        <div id="loggedout-form">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="flex-row">
                            <img src={logo}></img>
                            <div className="product">
                                <h5>{!free ? `${name} - ${productName}` : `${name}`}</h5>
                                <h1>{free ? `${productName}` : price}</h1>
                                <p className="interval">{!free ? `Every ${intervalCount} ${interval}(s)` : null}</p>
                            </div>
                        </div>

                    
                        {!free && (
                            <>
                                <div style={{marginTop:30, borderBottomLeftRadius:0, borderBottomRightRadius:0, borderBottom: "none"}} className="pricing">
                                    <label>Subtotal</label>
                                    <p>{free ? "FREE" : price}</p>
                                </div>
                                {
                                    coupon && (
                                        <div style={{borderTopLeftRadius:0, borderTopRightRadius:0, borderBottom: "none", borderBottomLeftRadius:0, borderBottomRightRadius:0}} className="pricing">
                                            <label className="green">Coupon Applied ({coupon})</label>
                                            <p className="green">{discount}</p>
                                        </div>
                                    )
                                }
                                <div style={{borderTopLeftRadius:0, borderTopRightRadius:0}} className="pricing">
                                    <label>Due today</label>
                                    <p>{free ? "FREE" : total}</p>
                                </div>
                            </>
                        )}
                        



                        {/* INFO UL LIST */}
                        <ul className="info">
                            {
                                highlightFeatures.map((feature, index) => {
                                  return (
                                    <li key={`feature-${index}`}>{feature}</li>
                                  )
                                })
                            }
                        </ul>

                    </div>

                    <div className="col-md-6">
                        <div>
                            <h5>Discord login required</h5>
                            <p>Before accessing the checkout page you must connect your Discord account using the button below.</p>
                        </div>

                        <button className="discord-button"
                            onClick={() => {
                                window.location.href = redirect;
                            }}
                        >                            
                            Login with Discord</button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoggedOut;
