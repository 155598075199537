import bootbox from "bootbox";

// Bind key to account function
export const bind = (key, setButtonDisabled, pullData, t) => {
  // Disable buttons
  setButtonDisabled(true);
  // Make API Post req
  fetch("/api/user/bind", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({key: key})
  })
  .then((val) => val.json())
    .then((val) => {
      if (val.success) {
        setButtonDisabled(false);
        // Re pull data
        return bootbox.alert(t("Successfully Bound Key"), () => {
          pullData();
        });
      } else {
        setButtonDisabled(false);
        return bootbox.alert(t(val.error));
      }
    }).catch((err) => {
      setButtonDisabled(false);
      return bootbox.alert(t("Failed request ") + err.toString());
    });
}


export const joinDiscord = (setButtonDisabled, t) => {
  setButtonDisabled(true);
  const options = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    }
  }
  fetch("/api/user/join", options)
    .then(val => val.json())
    .then((val) => {
      setButtonDisabled(false);
      if (val.success) {
        bootbox.alert(t("Successfully joined"), () => {
          window.open(`https://discordapp.com/channels/${val.server}`);
        });
      } else {
        bootbox.alert(t(val.error));
      }
    }).catch((err) => {
      setButtonDisabled(false);
      bootbox.alert(err.toString());
    })

}

export const unbind = (pullData, setKey, setButtonDisabled, t) => {
  setButtonDisabled(true);
  bootbox.confirm({
    message: t("Unbinding will cause you to be removed from the server. Don't worry you can bind back!"),
    buttons: {
      confirm: {
        label: t('Unbind Discord'),
        className: 'btn-success'
      },
      cancel: {
        label: t('Cancel'),
        className: 'btn-danger'
      }
    },
    callback: function(result) {
      if (result == true) {
        // Submit form
        fetch("/api/user/unbind", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(val => val.json())
          .then((val) => {
            setButtonDisabled(false);
            if (val.success) {
              setKey("")
              bootbox.alert(t("Successfully Unbound Account") , () => {
                pullData();
              });
              return
            } else {
              bootbox.alert(t(val.error));
            }
          }).catch((err) => {
            setButtonDisabled(false);
            bootbox.alert(err.toString());
          })
      } else {
        setButtonDisabled(false);
      }
    }
  })
}


export const requestReward = (reward, pullData, t) => {
  if (reward) {
    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ reward: reward })
    }

    fetch("/api/user/reward", options)
      .then(val => val.json())
      .then((val) => {
        if (val.success) {
          bootbox.alert(t("Successfully submit request, please wait for an admin to get in contact with you through Discord."), () => {
            pullData();
          });
        } else {
          bootbox.alert(t(val.error));
        }
      }).catch((err) => {
        bootbox.alert(err.toString());
      })
  } else {
    bootbox.alert(t("Please select a reward"));
  }
}
