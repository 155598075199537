import i18n from "i18next";
import {
    initReactI18next
} from "react-i18next";
import LngDetector from 'i18next-browser-languagedetector';
const en = require("./Locales/en.json");
const es = require("./Locales/es.json");

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: en
    },
    es: {
        translation: es
    }
};

i18n
    .use(LngDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en',
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;