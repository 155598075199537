import React, { useEffect, useState } from "react";
import {
  useParams
} from "react-router-dom";
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
import "../App.css"
import $ from "jquery"
import "bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import bootbox from "bootbox";
import CircleLoader from "../Components/CircleLoader"

const Order = () => {
  const { key } = useParams();
  const [data, setData] = useState({ name: "", scripts: [],color: "", secondaryColor: "" })
  const [dataLoaded, setDataLoaded] = useState(false)
  const { t, i18n } = useTranslation();
  const [setKey, setSetKey] = useState(null);

  const [googleAnalytics, setGoogleAnalytics] = useState("");
  const [metaPixel, setMetaPixel] = useState("");
  const [tiktokPixel, setTiktokPixel] = useState("");
  const [tiktokEvents, setTiktokEvents] = useState(null);

  useEffect(() => {
    if (key) {
      setSetKey(key);
    }
    // load data and set data loaded to true
    fetch("/orderInfo?key=" + key).then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDataLoaded(true);
          if (res) {
            if (res.metaPixel) {
              setMetaPixel(res.metaPixel);
            }

            if (res.googlePixel) {
              setGoogleAnalytics(res.googlePixel);
            }
            if (res.tiktokPixel) {
              setTiktokPixel(res.tiktokPixel);
            }
            if (res.tiktokEvents) {
              setTiktokEvents(res.tiktokEvents);
            }
          }

          setData(res)


        } else {
          // some error
          return bootbox.alert(res.error);
        }
      }).catch((err) => {
        console.log(err);

        return bootbox.alert(err.toString());
      })
  }, [])

  const copyToClipboard = (element) => {
    $(element).trigger("select");
    document.execCommand("copy");
    $("#dashboard").removeClass("disabled");
    $("#dashboard").attr("disabled", false);
  }

  return (
    <div>
      {dataLoaded === true ? <div><Helmet>
        <title>Order - {data.name}</title>
        <style>{`
              :root {
              --main-color: ${data.color};
              --secondary-color: ${data.secondary_color};
              }
              html, body {
                background-color: ${data.color} !important;
              }`}
        </style>
       
        {data.scripts.map(script => (
          <script src={script} ></script>
        ))}
    </Helmet>

        {
              googleAnalytics && (
                <Helmet>
                  <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalytics}`}></script>
                  <script>
                    {`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag("js", new Date());
  
                      gtag("config", '${googleAnalytics}');
                    `}
                    </script>
                </Helmet>
              )
            }

            {
              metaPixel && (
                <Helmet>
                <script>
                  {`
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '${metaPixel}');
                  fbq('track', 'PageView');
                  `}
                </script>
                </Helmet>
              )
            }

            {
              tiktokPixel && (
                <Helmet>
                <script>
                  {`
                  !function (w, d, t) {
                    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
                    ttq.load('${tiktokPixel}');
                    ttq.page();
                  }(window, document, 'ttq');
                  `}
                </script>
                </Helmet>
              )
            }

            {
              tiktokEvents && (
                <Helmet>
                <script>
                  {`
                    ttq.instance('${tiktokEvents.id}').track('${tiktokEvents.name}');
                  `}
                </script>
                </Helmet>
              )
            }
        <div id="order-complete">
          <h1>{t("Order Complete!")}</h1>

          
          {setKey ? (
            <>
            
              <p>{t("Your order has been completed, please copy your key and move onto the dashboard to bind your key.")}</p>

              <h2>{t("Key")}:</h2>
              <input readOnly={true} id="key-field" type="text" value={setKey} />
              <button onClick={() => copyToClipboard("#key-field")} style={{ cursor: "pointer", backgroundColor: "#FFF", color: "var(--main-color)", border: "1px solid #DDDDE0" }} className="order-btn">{t("Copy Key")}</button>
              <button onClick={() => window.open('/dashboard', '_blank')} id="dashboard" style={{ color: "var(--secondary-color)", backgroundColor: "var(--main-color)" }} className="order-btn disabled">{t("Dashboard")}</button>
            </>
            ) : (
              <>
              <p>{"Your order has been completed, please move onto the dashboard to access the server and your account."}</p>
              <button onClick={() => window.open('/dashboard', '_blank')} id="dashboard" style={{ color: "var(--secondary-color)", backgroundColor: "var(--main-color)" }} className="order-btn disabled">{t("Dashboard")}</button>
            </>
          )}

        </div> 
      </div>

        
        : <div>
          <div id="navbar">
          </div>

          <div>
            <CircleLoader />
          </div>

        </div>}
    </div>)
}

export default Order;
