import React, { useEffect, useState, useRef } from "react";
import { Helmet } from 'react-helmet'
import "../App.css"
import $ from "jquery"
import { useTranslation } from 'react-i18next';
import { PaymentRequestButtonElement, Elements, CardElement } from '@stripe/react-stripe-js';
import StripeCardElementGrabber from "../Components/StripeCardElementGrabber"
import StripePaymentRequestButton from "../Components/StripePaymentRequestButton";
import {loadStripe} from '@stripe/stripe-js';
import CircleLoader from "../Components/CircleLoader"
import LoggedOut from "../Components/LoggedOut"
import stripeBadge from '../partner.png';
import ternaryBadge from '../ternary-badge.png';
import countries from '../Actions/Register/Countries';
import states from '../Actions/Register/States';
import provinces from '../Actions/Register/Provinces';
import bootbox from "bootbox";
import "bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import currencies from "../Locales/currencies";
import { current } from "immer";


const Register = () => {
  // Define variables
  const { t, i18n } = useTranslation();
  // Page data
  const [data, setData] = useState({useAuthorizeNet: false, purchaseMessage: "", docusignId: "", docusignClickId:"", scripts: [], color: "", secondary_color: "", name: "", terms: "", logo: "", pk_key: "", products: [], taxRate:0,require_billing: false, authorizeNet: false })

  const [bgImage, setBgImage] = useState(null);
  const [highlightFeatures, setHighlightFeatures] = useState([]);

  const [dataLoaded, setDataLoaded] = useState(false)
  const [stripePromise, setStripePromise] = useState();
  const [stripeLoaded, setStripeLoaded] = useState(false)
  const [loggedOut, setLoggedOut] = useState(false);
  const [loginRedirect, setLoginRedirect] = useState("");
  const [collectPhone, setCollectPhone] = useState(false);

  // Tracking
  const [googleAnalytics, setGoogleAnalytics] = useState("");
  const [metaPixel, setMetaPixel] = useState("");
  const [tiktokPixel, setTiktokPixel] = useState("");
  const [tiktokEvents, setTiktokEvents] = useState(null);
  const [linkMink, setLinkMink] = useState("");

  // Product selection elements
  const [free, setFree] = useState(true)
  const [initialPrice, setInitialPrice] = useState(0);
  const [price, setPrice] = useState("")
  const [totalPrice, setTotalPrice] = useState("")
  const [totalSubPrice, setTotalSubPrice] = useState("")
  const [priceInt, setPriceInt] = useState(0)
  const [discount, setDiscount] = useState(0);

  const [name, setName] = useState(true)
  const [lifetime, setLifetime] = useState(false)
  const [tax, setTax] = useState(0)

  // Form elements
  const [membership, setMembership] = useState("")
  const [trialDays, setTrialDays] = useState(0)
  const [email, setEmail] = useState("")
  const [cardNumber, setCardNumber] = useState("");
  const [cardMonth, setCardMonth] = useState("01");
  const [cardYear, setCardYear] = useState("23");
  const [cardCvv, setCardCvv] = useState("");
  const [coupon, setCoupon] = useState("")
  const [validCoupon, setValidCoupon] = useState("")
  const [couponColor, setCouponColor] = useState("")
  const [couponAlert, setCouponAlert] = useState("")
  const [checkmark, setcheckmark] = useState(false);
  const [cardErrors, setCardErrors] = useState("")
  const [submit, setSubmit] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [billingRequired, setBillingRequired] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [address, setAddress] = useState("")
  const [address2, setAddress2] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [country, setCountry] = useState("US")
  const [state, setState] = useState("AL")
  const [city, setCity] = useState("")
  const [phone, setPhone] = useState("")
  // TODO: StockDads only
  // const [marketing, setMarketing] = useState("Facebook");

  const [password, setPassword] = useState("")
  const [idempotent, setIdempotent] = useState("")
  const [paymentRequestObject, setPaymentRequestObject] = useState(null);

  let grabberRef = useRef();

  // On page load event
  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let passwordQuery = params.get('password');
    setPassword(passwordQuery)
    let codeQuery = params.get('code');
    let couponQuery = params.get('coupon');
    let refQuery = params.get('ref');
    var url = `/registerInfo?password=${passwordQuery}&coupon=${couponQuery}&ref=${refQuery}`;
    if (codeQuery) {
      url = `/registerInfo?password=${passwordQuery}&coupon=${couponQuery}&ref=${refQuery}&code=${codeQuery}`
    }
    // load data and set data loaded to true
    fetch(url).then((res) => res.json())
      .then((res) => {
        // TODO: Set bg image from api
        if (res.background) {
          setBgImage(res.background);
        }


        if (res.success) {
          setData(res)
        } else {
          if (res.requireLogin) {
            setLoggedOut(true);
            setLoginRedirect(res.redirect);
            setData(res);

          } else {
            window.location.href = res.redirect;
          }
        }
      }).catch((err) => {
        return bootbox.alert(err.toString());
      })
  }, [])



  // On data loaded event
  useEffect(async () => {
    console.log(data);
    if (data.useAuthorizeNet || data.pk_key !== "") {
      if (data.products) {
        if (data.products.length == 0) {
          return window.location.href = '/dashboard';
        }
  
        if (data.user && data.user.subscriptions) {
          // Verify user hasn't purchased
          var alreadyPurchased = false;
          var userPlans = [];
          data.user.subscriptions.map(subscription => {
            userPlans.push(subscription.plan);
          });
          data.products.map(product => {
            if (userPlans.includes(product.plan)) {
              alreadyPurchased = true;
            }
          });
          if (alreadyPurchased) {
            return window.location.href = '/dashboard';
          }
        }
  
  
        if (!data.useAuthorizeNet) {
          var stripePromiseVar = await loadStripe(data.pk_key, {
            betas: [
              "pay_button_element_beta_1",
              "elements_enable_deferred_intent_beta_1",
            ],
            stripeAccount: data.stripeAccount
          });
          console.log(stripePromiseVar);
          setStripePromise(stripePromiseVar);
          setStripeLoaded(true);
        } else {
          setStripeLoaded(true);
        }
      }



    }
  }, [data])


  useEffect(() => {
    if (stripeLoaded === true) {
      console.log("striped loaded");
      setBillingRequired(data.require_billing || data.useAuthorizeNet ? true : false);
      setCollectPhone(data.collectPhone || false);
      // Load the first product pricing & name
      var localCurrency = data.products[0].currency ? data.products[0].currency : "USD";
      var taxRate = data.taxRate;
      if (data.products[0].price === 0) {
        var localPrice = 0;
        if (data.products[0].initialPayment && data.products[0].initialPayment.price) {
          localPrice += data.products[0].initialPayment.price;
          createPaymentRequestObject(data.products[0].name, localPrice * 100, localCurrency.toLowerCase());
        }
        setPrice(`${currencies[localCurrency.toUpperCase()].symbol.default.display}0`)
        setTotalPrice(`${currencies[localCurrency.toUpperCase()].symbol.default.display}${localPrice}`)
        setPriceInt(localPrice);
        setTotalSubPrice(`${currencies[localCurrency.toUpperCase()].symbol.default.display}0`)
        setFree(true);        

        if (data.products[0].features) {
          var features = [];
          data.products[0].features.map(feature => {
            features.push(feature);
          })
          setHighlightFeatures(features);
        }

      } else {
        var localPrice = data.products[0].price;
        if (taxRate > 0) {
          localPrice = localPrice + (localPrice * (taxRate/100));
        } 

        var totalPrice = localPrice;
        if (data.products[0].initialPayment && data.products[0].initialPayment.price) {
          totalPrice += data.products[0].initialPayment.price;
        }

        setPrice(`${currencies[localCurrency.toUpperCase()].symbol.default.display}${data.products[0].price.toFixed(2)}`)
        setTotalPrice(`${currencies[localCurrency.toUpperCase()].symbol.default.display}${totalPrice.toFixed(2)}`)
        setPriceInt(totalPrice.toFixed(2));
        setTotalSubPrice(`${currencies[localCurrency.toUpperCase()].symbol.default.display}${localPrice.toFixed(2)}`)
        setFree(false);
        createPaymentRequestObject(data.products[0].name, localPrice * 100, localCurrency.toLowerCase());
      }
      setName(data.products[0].name);
      setMembership(data.products[0].plan);
      setLifetime(data.products[0].interval === "one time" ? true : false);
      // Trial days
      if(data.products[0].trialDays && data.products[0].trialDays > 0) {
        setTrialDays(data.products[0].trialDays);

        var localPrice = 0;
        if (data.products[0].initialPayment && data.products[0].initialPayment.price) {
          localPrice+= data.products[0].initialPayment.price;
        }

        setTotalPrice(`${currencies[localCurrency.toUpperCase()].symbol.default.display}${localPrice}`);
        setPriceInt(localPrice);
        createPaymentRequestObject(data.products[0].name, localPrice * 100, localCurrency.toLowerCase())
      } else {
        setTrialDays(0);
      }


      setIdempotent(uuidv4());


      var urlCoupon = new URLSearchParams(window.location.search).get('coupon');
      if (urlCoupon) {
        setCoupon(urlCoupon);
      }
      setTax(data.taxRate);

      if (data.metaPixel) {
        setMetaPixel(data.metaPixel);
      }
      if (data.googlePixel) {
        setGoogleAnalytics(data.googlePixel);
      }
      if (data.tiktokPixel) {
        setTiktokPixel(data.tiktokPixel);
      }
      if (data.tiktokEvents) {
        setTiktokEvents(data.tiktokEvents);
      }
      if (data.linkMink) {
        setLinkMink(data.linkMink);
      }


      // Set data loaded true
      setDataLoaded(true);
    

      return
    }
  }, [stripeLoaded])

  useEffect(() => {
    if (dataLoaded === true) {
      console.log(tax);
    }
  }, [tax]);


  useEffect(() => {
    if (dataLoaded === true) {
      if (coupon) {
        handleCouponClick();
      }
    }
  }, [dataLoaded]);



  // Randomly generate UUIDV4 for request
  const uuidv4 = () =>  {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  


  // On buttons / Input events


  // On different membership selected
  const handleMembershipChange = (e) => {
    let selected = e.target.value;
    let release;
    for (let i = 0; i < data.products.length; i++) {
      if (data.products[i].plan === selected) {
        release = data.products[i];
        break;
      }
    }

    var localCurrency = release.currency ? release.currency.toUpperCase() : "USD";
    setMembership(selected);
    setName(release.name);
    var localPrice = release.price;
    if (tax > 0 && localPrice > 0) {
      localPrice = localPrice + (localPrice * (tax/100));
    }
    var totalPrice = localPrice;
    if (release.initialPayment && release.initialPayment.price) {
      totalPrice += release.initialPayment.price;
    }

    setPrice(`${currencies[localCurrency.toUpperCase()].symbol.default.display}${release.price.toFixed(2)}`);
    setTotalPrice(`${currencies[localCurrency.toUpperCase()].symbol.default.display}${totalPrice.toFixed(2)}`);
    setPriceInt(totalPrice.toFixed(2));
    setTotalSubPrice(`${currencies[localCurrency.toUpperCase()].symbol.default.display}${localPrice.toFixed(2)}`);
    createPaymentRequestObject(release.name, localPrice * 100, localCurrency.toLowerCase());
    setLifetime(release.interval === "one time" ? true : false);

    setCoupon("");
    setValidCoupon("");
    setCouponAlert("");
    setCouponColor("");



    if (release.price == 0) {
      setFree(true);
    } else {
      setFree(false);
    }

    // Trial days
    if(release.trialDays && release.trialDays > 0) {
      setTrialDays(release.trialDays);
      var localPrice = 0;
      if (release.initialPayment && release.initialPayment.price) {
        localPrice += release.initialPayment.price;
      }
      setTotalPrice(`${currencies[localCurrency.toUpperCase()].symbol.default.display}${localPrice}`);
      setPriceInt(localPrice);
      createPaymentRequestObject(data.products[0].name, 0, localCurrency.toLowerCase());
    } else {
      setTrialDays(0);
    }


  }



  // Coupon applied
  const handleCouponClick = () => {
    let release;
    for (let i = 0; i < data.products.length; i++) {
      if (data.products[i].plan === membership) {
        release = data.products[i];
        break;
      }
    }
    if (!coupon) {
      var localPrice = release.price;
      if (tax > 0 && price > 0) {
        localPrice = localPrice + (localPrice * (tax/100));
      }
    
      setTotalSubPrice(`${currencies[release.currency ? release.currency.toUpperCase() : "USD"].symbol.default.display}${localPrice.toFixed(2)}`);
      if (!trialDays > 0) {
        if (release.initialPayment && release.initialPayment.price) {
          localPrice += release.initialPayment.price;
        }
        setTotalPrice(`${currencies[release.currency ? release.currency.toUpperCase() : "USD"].symbol.default.display}${localPrice.toFixed(2)}`);
        setPriceInt(localPrice.toFixed(2));
        setPaymentRequestObject(null);
        createPaymentRequestObject(release.name, localPrice * 100, release.currency ? release.currency.toLowerCase() : "usd");
      }
      setCouponColor("coupon-red");
      setCouponAlert(t("Please enter a coupon"));
      return;
    }
    setCouponAlert("");
    setCouponColor("");

    fetch(`/api/purchase/coupons/${coupon}/${release.product}`).then((val) => val.json())
      .then((val) => {
        if (val.success) {
          setValidCoupon(coupon);
          setCouponColor("green");
          if (val.discountType == "amount") {
            setCouponAlert(`${t('Applied Coupon')}: ${currencies[release.currency ? release.currency.toUpperCase() : "USD"].symbol.default.display}${val.discount}`);
            setDiscount(`${currencies[release.currency ? release.currency.toUpperCase() : "USD"].symbol.default.display}${val.discount}`);
            var newPrice = release.price;


            if (tax > 0 && newPrice > 0) {
              newPrice = newPrice + (newPrice * (tax/100));
            }

            var newTotalPrice = newPrice;
            if (release.initialPayment && release.initialPayment.price) {
              newTotalPrice += release.initialPayment.price;
            }

            var newPrice = release.price - parseInt(val.discount);
            var newTotalPrice = newTotalPrice - parseInt(val.discount);

            if (newPrice < 0) {
              newPrice = 0;
            }

            if (newTotalPrice < 0) {
              newTotalPrice = 0;
            }

            if (!trialDays > 0) {
              setTotalPrice(`${currencies[release.currency ? release.currency.toUpperCase() : "USD"].symbol.default.display}${newTotalPrice.toFixed(2)}`);
              setPriceInt(newTotalPrice.toFixed(2));
              setPaymentRequestObject(null);
              createPaymentRequestObject(release.name, newTotalPrice * 100, release.currency ? release.currency.toLowerCase() : "usd");
            }
            if (val.forever || val.duration > 1) {
              setTotalSubPrice(`${currencies[release.currency ? release.currency.toUpperCase() : "USD"].symbol.default.display}${newPrice.toFixed(2)}`);
            }
          } else if (val.discountType == "percent") {
            setCouponAlert(`${t('Applied Coupon')}: ${val.discount}%`);
            setDiscount(`${val.discount}%`);
            var newPrice = release.price;
            var newTotalPrice = release.price;
            if (release.initialPayment && release.initialPayment.price) {
              newTotalPrice += release.initialPayment.price;
            }
            if (tax > 0 && newPrice > 0) {
              newPrice = newPrice + (newPrice * (tax/100));
              newTotalPrice = newTotalPrice + (newTotalPrice * (tax/100));
            }

            newTotalPrice = newTotalPrice - (newTotalPrice * parseInt(val.discount) / 100)
            newPrice = newPrice - (newPrice * parseInt(val.discount) / 100);


            if (!trialDays > 0) {
              setTotalPrice(`${currencies[release.currency ? release.currency.toUpperCase() : "USD"].symbol.default.display}${newTotalPrice.toFixed(2)}`);
              setPriceInt(newTotalPrice.toFixed(2));
              setPaymentRequestObject(null);
              createPaymentRequestObject(release.name, newTotalPrice * 100, release.currency ? release.currency.toLowerCase() : "usd");
            }
            if (val.forever || val.duration > 1) {
              setTotalSubPrice(`${currencies[release.currency ? release.currency.toUpperCase() : "USD"].symbol.default.display}${newPrice.toFixed(2)}`);
            }
          }
          return;
        } else {
          setCoupon("");
          setCouponAlert(val.error);
          setCouponColor("coupon-red");
          if (!trialDays > 0) {
            var newPrice = release.price;

            if (tax > 0 && newPrice > 0) {
              newPrice = newPrice + (newPrice * (tax/100));
            }

            var totalPrice = newPrice;
            if (release.initialPayment && release.initialPayment.price) {
              totalPrice += release.initialPayment.price;
            }

            setTotalPrice(`${currencies[release.currency ? release.currency.toUpperCase() : "USD"].symbol.default.display}${totalPrice.toFixed(2)}`);
            setPriceInt(totalPrice.toFixed(2));
            setTotalSubPrice(`${currencies[release.currency ? release.currency.toUpperCase() : "USD"].symbol.default.display}${newPrice.toFixed(2)}`);
            setPaymentRequestObject(null);
            createPaymentRequestObject(release.name, totalPrice * 100, release.currency ? release.currency.toLowerCase() : "usd");
          }
          return;
        }


      }).catch((err) => {
        return bootbox.alert(t("Failed request ") + err.toString(), () => {
          window.location.reload();
        });
      })
  }


  // Create payment request button for apple + google pay
  const createPaymentRequestObject = async (product, amount, currency) => {
    setPaymentRequestObject({
        country: "US",
        currency: currency,
        amount: amount
    });
  }


  // Purchase form submitting
  const handleRegisterSubmit = () => {
    if (!submit) {
      setSubmit(true);
      // Check if DocuSign exists
      if (data.docusignId !== "" && data.docusignClickId !== "") {
        return window.docuSignClick.Clickwrap.render({
          environment: 'https://na4.docusign.net',
          accountId: data.docusignId,
          clickwrapId: data.docusignClickId,
          clientUserId: `${$("#email").val()} @ ${Date.now()}`,
          onAgreed: registerSubmit
      }, '#clickwrap');
      } else {
        registerSubmit();
      }
    }
  }

  const paymentButtonSubmit = (event) => {
    let token = event.token;
    if (coupon && !validCoupon) {
      setButtonDisabled(false);
      setSubmit(false);
      setCouponAlert(t("Please apply the coupon entered"));
      setCouponColor("coupon-red");
      return;
    }
    var formData = {
      membership: membership
    };
    if (validCoupon) {
      formData.coupon = validCoupon;
    }
    // Check terms of service and all fields filled in
    formData.password = `${new URLSearchParams(window.location.search).get('password')}`;    
    if (formData.membership) {
      // Get remaining stuff from event
        // First n Last name
        formData.fname = `${event.payerName.split(" ")[0]}`;
        if (event.payerName.split(" ").length > 1) {
          console.log(event.payerName);
          formData.lname = event.payerName.split(" ")[1];
        } else {
          formData.lname = "n/a";
        }
        // Email
        formData.email = event.payerEmail;
        formData.terms = 1;
        // Address if needed
        if (billingRequired) {
          formData.phone = event.payerPhone ? event.payerPhone : "n/a";
          formData.address = event.token.card.address_line1;
          formData.address2 = event.token.card.address_line2 ? event.token.card.address_line2 : "";
          formData.country = event.token.card.country;
          formData.state = event.token.card.address_state;
          formData.city = event.token.card.address_city;
          formData.zip = event.token.card.address_zip;
        }

        if (collectPhone) {
          formData.phone = event.payerPhone ? event.payerPhone : "n/a";
        }


        if (data.docusignId !== "" && data.docusignClickId !== "") {
          event.complete('success');
          return window.docuSignClick.Clickwrap.render({
            environment: 'https://na4.docusign.net',
            accountId: data.docusignId,
            clickwrapId: data.docusignClickId,
            clientUserId: `${event.payerEmail} @ ${Date.now()}`,
            onAgreed: () => {
              stripeTokenHandler(formData, token, event);
            }
          }, '#clickwrap');
        } else {
          stripeTokenHandler(formData, token, event);
        }
    } else {
      event.complete('fail'); 
      bootbox.alert(t("Please fill in all fields"));
    }

  }

  const registerSubmit = () => {
    if (!buttonDisabled) {
      setButtonDisabled(true);

      

      // Stripe checkout
      if (!data.useAuthorizeNet) {
        if (coupon && !validCoupon) {
            setButtonDisabled(false);
            setSubmit(false);
            setCouponAlert(t("Please apply the coupon entered"));
            setCouponColor("coupon-red");
            return;
          }
          
          var formData = {
            email: email,
            membership: membership,
            terms: checkmark,
            fname: firstName,
            lname: lastName,
            // userLead: marketing 
          }
          if (billingRequired) {
            formData = {
              email: email,
              membership: membership,
              terms: checkmark,
              fname: firstName,
              lname: lastName,
              // userLead: marketing,
              phone: phone,
              address: address,
              address2: address2,
              country: country,
              state: state,
              city:city,
              zip: zipCode
            }
          }

          if (collectPhone) {
            formData.phone = phone;
          }
    
          if (validCoupon) {
            formData.coupon = validCoupon;
          }
          if (formData.email && formData.membership && formData.terms != 0) {
            if (!free) {
              grabberRef.current.getToken().then((result) => {
                if (result.error) {
                  // Inform the user if there was an error
                  setButtonDisabled(false);
                  setSubmit(false);
                  setCardErrors(result.error.message);
                } else {
                  // Send the token to your server
                  stripeTokenHandler(formData, result.token);
                }
              }).catch((err) => {
                setButtonDisabled(false);
                setSubmit(false);
                return bootbox.alert(err.message);
              })
            } else {
              stripeTokenHandler(formData);
            }
    
          } else {
            setButtonDisabled(false);
            setSubmit(false);
            return bootbox.alert(t("Please fill in all fields"));
          }
        }
       else {

        var formData = {
          email: email,
            membership: membership,
            terms: checkmark,
            fname: firstName,
            lname: lastName,
            phone: phone,
            address: address,
            address2: address2,
            country: country,
            state: state,
            city:city,
            zip: zipCode,
            cardNumber: !free ? cardNumber : null,
            cardExpiry: !free ? `${cardMonth}/${cardYear}` : null,
            cardCvv: !free ? cardCvv : null
        }
        

        setCardErrors("")

        formData.password = `${new URLSearchParams(window.location.search).get('password')}`;
        if (formData.email && formData.membership && formData.terms && cardMonth && cardYear && cardCvv && cardNumber) {
          // submit form
          fetch("/api/authorizeNet/purchase", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          }).then((val) => val.json())
            .then((val) => {
              if (val.success) {

                if (data.purchaseMessage) {
                  bootbox.alert(data.purchaseMessage, () => {
                    return window.location.href = val.redirect;
                  });
                } else {
                  return window.location.href = val.redirect; 
                }

              } else {               
                setButtonDisabled(false);
                setSubmit(false);
                return bootbox.alert(t(val.error));
              }

            }).catch((err) => {
              setButtonDisabled(false);
              setSubmit(false);
              return bootbox.alert(t("Failed request ") + err.toString());
            })
          } else {
            setButtonDisabled(false);
            setSubmit(false);
            bootbox.alert(t("Please fill in all fields"));
          }
      }
    }

  }


  // Stripe token created
  const stripeTokenHandler = (formData, token, event, ignoreTrialError) => {
    // Submit the form
    // clear card errors
    setCardErrors("")
    if (free != true) {
      formData.token = token.id;
      formData.ip = token.client_ip;
    }
    formData.ignoreTrialError = ignoreTrialError ? true : false;
    formData.password = `${new URLSearchParams(window.location.search).get('password')}`;
    if (formData.email && formData.membership && formData.terms) {
      if (window.lm_data) {
        formData.lm_data = window.lm_data;
      }
      // submit form
      fetch("/api/purchase", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Idempotency-Key': idempotent
        },
        body: JSON.stringify(formData)
      }).then((val) => val.json())
        .then((val) => {
          if (val.success) {
            console.log(val);
            if (val.requiresAction) {
              // Reset the idempotency key
              setIdempotent(uuidv4());

              console.log(val.clientSecret, val.stripeAccount)

              // Use Stripe.js to handle required card action
              stripePromise.confirmCardPayment(val.clientSecret).then(function(result) {
                console.log(result);
                if (result.error) {
                  // Show error in payment form
                  setCardErrors(result.error.message);
                  setButtonDisabled(false);
                  setSubmit(false);
                } else {
                  // The payment has succeeded. Display a success message.
                  if (data.purchaseMessage) {
                    bootbox.alert(data.purchaseMessage, () => {
                      return window.location.href = val.redirect;
                    });
                  } else {
                    return window.location.href = val.redirect; 
                  }
                    
                }
              });  
              
              return;
            } else {
              if (event) {
                event.complete('success');
              }
  
              if (data.purchaseMessage) {
                bootbox.alert(data.purchaseMessage, () => {
                  return window.location.href = val.redirect;
                });
              } else {
                return window.location.href = val.redirect; 
              }
            }


          } else {
            // Reset the idempotency key
            setIdempotent(uuidv4());
            if (event) {
              event.complete('fail'); 
              // Trial error so trigger purchase option
              if (val.error === "Trial already purchased") {
                
                bootbox.confirm({
                  message: t("The free trial has already been applied, if you click continue, your membership fee will be due at the time of signup"),
                  buttons: {
                    confirm: {
                      label: t('Continue'),
                      className: 'btn-success'
                    },
                    cancel: {
                      label: t('Cancel'),
                      className: 'btn-danger'
                    }
                  },
                  callback: function(result) {
                    if (result == true) {
                      return stripeTokenHandler(formData, token, event, true);                      
                    } else {
                      return;
                    }
                  }
                });

              } else {
                return bootbox.alert(t(val.error));
              }
            } else {
              setButtonDisabled(false);
              setSubmit(false);
              // Trial error so trigger purchase option
              if (val.error === "Trial already purchased") {
                
                bootbox.confirm({
                  message: t("The free trial has already been applied, if you click continue, your membership fee will be due at the time of signup"),
                  buttons: {
                    confirm: {
                      label: t('Continue'),
                      className: 'btn-success'
                    },
                    cancel: {
                      label: t('Cancel'),
                      className: 'btn-danger'
                    }
                  },
                  callback: function(result) {
                    if (result == true) {
                      return stripeTokenHandler(formData, token, event, true);                      
                    } else {
                      return;
                    }
                  }
                });

              }
              else {
                
                return bootbox.alert(t(val.error));
              }
            }
          }

        }).catch((err) => {
          if (event) {
            event.complete('fail'); 
            return bootbox.alert(t("Failed request ") + err.toString());
          }
          else {
            setButtonDisabled(false);
            setSubmit(false);
            return bootbox.alert(t("Failed request ") + err.toString());
          }
        })
    } else {
      if (event) {
        event.complete('fail'); 
        bootbox.alert(t("Please fill in all fields"));
      }
      else {
        setButtonDisabled(false);
        setSubmit(false);
        bootbox.alert(t("Please fill in all fields"));
      }
    }
  }

  useEffect(() => {
    console.log(googleAnalytics)
  }, [googleAnalytics]);


  useEffect(() => {
    console.log(linkMink);
  }, [linkMink]);

  // Render methods & front end
  return (
    <div>
      {dataLoaded === true ?
        <div>
          <Helmet>
          <title>Purchase - {data.name}</title>

          {/* IF bg image */}
          {
            bgImage ? (
              <style>
                {`
                  html, body {
                    background-image: url(${bgImage}) !important;
                      background-size: 100% 100%;
                      background-repeat: no-repeat;
                      background-attachment: fixed;
                  }
                `}
              </style>
            ) : (
              <style>
                {`
                  html, body {
                    background-color: ${data.color} !important;
                  }
                `}
              </style>
            )
          }
            <style>
            {`
                  :root {
                  --main-color: ${data.color};
                  --secondary-color: ${data.secondary_color};
                  }
                  
                  .hidden {
                     display: none !important;
                   }`
            }
            </style>
          </Helmet>

            <Helmet>
            {data.scripts.map(script => (
              script === 'https://cdn.usefathom.com/script.js' && data.name === 'PlayTyme' ? (
                <script data-site="CSEASAAW" src={script} ></script>
              )
              : (
                <script src={script} ></script>
              )
            ))}
            </Helmet>


            {
              googleAnalytics && (
                <Helmet>
                  <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalytics}`}></script>
                </Helmet>
              )
            }

            {
              googleAnalytics && (
                <Helmet>
                <script>
                  {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag("js", new Date());

                    gtag("config", '${googleAnalytics}');
                  `}
                </script> 
                </Helmet>
              )
            }

            {
              metaPixel && (
                <Helmet>
                <script>
                  {`
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '${metaPixel}');
                  fbq('track', 'PageView');
                  `}
                </script>
                </Helmet>
              )
            }
            
            {
              tiktokPixel && (
                <Helmet>
                <script>
                  {`
                  !function (w, d, t) {
                    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
                    ttq.load('${tiktokPixel}');
                    ttq.page();
                  }(window, document, 'ttq');
                  `}
                </script>
                </Helmet>
              )
            }


            {
              tiktokEvents && (
                <Helmet>
                <script>
                  {`
                    ttq.instance('${tiktokEvents.id}').track('${tiktokEvents.name}');
                  `}
                </script>
                </Helmet>
              )
            }

            {
              linkMink && (
                <Helmet>
                  <script>
                    {`
                    // lm.js must be initialized with your API key
                      const lm = LinkMink('${linkMink}')

                      lm.initTracking();

                      console.log(lm);
                      // retrieve the referral information
                      const lm_data = lm.getReferralData();
                      window.lm_data = lm_data;
                    `}
                  </script>
                  </Helmet>
              )
            }
          
          {/* If not logged out load checkout page */}
          {!loggedOut ? (
              <form id="register-form" onSubmit={(e) => { e.preventDefault(); handleRegisterSubmit() }}>
              <div id="clickwrap">
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <h1>{data.name}</h1>
  
                    {/* {!data.useAuthorizeNet && paymentRequestObject !== null && dataLoaded === true && !free ? (
      
                      <Elements stripe={stripePromise} options={paymentRequestObject}>    
                        <StripePaymentRequestButton paymentButtonSubmit={paymentButtonSubmit}/>
                      </Elements>

                    ) : null } */}
  
  
                    <select name="membership" id="membership" required value={membership}onChange={e => handleMembershipChange(e)}>
                      <option key={-1} value="" disabled>Please select membership</option>
                      {
                        data.products.map(product => {
                          return (
                            <option key={product.plan} value={product.plan}>
                              {product.name}
                            </option>
                          )
                        })
                      }
                    </select>
  
                    <div style={{marginTop:0, marbinBottom:0}} className="row">
                      <div style={{marginTop:0, marbinBottom:0}} className="col-sm-6">
                        <input id="fname" className="input-field billing-field" name="firstname" type="text" placeholder={t("First Name")} required value={firstName} onChange={e => setFirstName(e.target.value)} />
                      </div>
                      <div style={{marginTop:0, marbinBottom:0}} className="col-sm-6">
                        <input id="lname" className="input-field billing-field" name="lastname" type="text" placeholder={t("Last Name")} required value={lastName} onChange={e => setLastName(e.target.value)} />
                      </div>
                    </div>
  
                    <input id="email" className="input-field" name="email" type="email" placeholder="Email" required value={email} onChange={e => setEmail(e.target.value)} />
                    {!billingRequired && collectPhone && (
                      <input id="phone" className="input-field" name="phone" type="tel" placeholder={t("Phone")} required value={phone} onChange={e => setPhone(e.target.value)} />
                    )}
                    {billingRequired === true ? 
                      (
                        <>
                          <input id="phone" className="input-field" name="phone" type="tel" placeholder={t("Phone")} required value={phone} onChange={e => setPhone(e.target.value)} />
                          <input id="address" className="input-field" name="address" type="text" placeholder={t("Address")} required value={address} onChange={e => setAddress(e.target.value)} />
                          <input id="address2" className="input-field" name="address2" type="text" placeholder={t("Address line 2")} value={address2} onChange={e => setAddress2(e.target.value)} />
                          <div style={{marginTop:0, marbinBottom:0}} className="row">
                            <div style={{marginTop:0, marbinBottom:0}} className="col-sm-6">
                              <select onChange={e => setCountry(e.target.value)} id="country" name="country" className="input-field billing-field">
                                {Object.keys(countries.countries).map((innerAttr, index) => {
                                    return (
                                      <option value={innerAttr}>{countries.countries[innerAttr]}</option>
                                    )})
                                }
                              </select>
                            </div>
                            <div style={{marginTop:0, marbinBottom:0}} className="col-sm-6">
                            {
                              country === "US" ? 
                              <select onChange={e => {setState(e.target.value)}} id="state" name="state" className="input-field billing-field">
                                {Object.keys(states).map((innerAttr, index) => {
                                    return (
                                      <option value={innerAttr}>{states[innerAttr]}</option>
                                    )})
                                }
                              </select> : null                        
                            }
                            {country === "CA" ?
                                  (
                                    <select onChange={e => setState(e.target.value)} id="state" name="state" className="input-field billing-field">
                                      {Object.keys(provinces).map((innerAttr, index) => {
                                          return (
                                            <option value={innerAttr}>{provinces[innerAttr]}</option>
                                          )})
                                      }
                                    </select>
                                  )
                                : null
                            }
                            
                            </div>
                        </div>
  
                        <div style={{marginTop:0, marbinBottom:0}} className="row">
                          <div style={{marginTop:0, marbinBottom:0}} className="col-sm-6">
                            <input id="city" className="input-field billing-field" name="city" type="text" placeholder={t("City")} required value={city} onChange={e => setCity(e.target.value)} />
                          </div>
                          <div style={{marginTop:0, marbinBottom:0}} className="col-sm-6">
                            <input id="zip" className="input-field billing-field" name="zip" type="text" placeholder={t("Zip code")} required value={zipCode} onChange={e => setZipCode(e.target.value)} />
                          </div>
                        </div>
                        </>
                      ) :
                      null
                    }
                    {dataLoaded === true && !free && !data.useAuthorizeNet ? 
                            
                          <Elements stripe={stripePromise}>
                              <div id="card-element">
                                <CardElement
                                  id="cardElement"
                                  options={{
                                    style: {
                                      base: {
                                        color: '#32325d',
                                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                                        fontSmoothing: 'antialiased',
                                        fontSize: '16px',
                                        '::placeholder': {
                                          color: '#aab7c4'
                                        }
                                      },
                                      invalid: {
                                        color: '#fa755a',
                                        iconColor: '#fa755a'
                                      }
                                    },
                                  }}
                                />
                                <StripeCardElementGrabber ref={grabberRef} />
                              </div>
                            </Elements>
  
                      
                    : null}
  
                    {dataLoaded === true && !free && data.useAuthorizeNet ?(
                      <div id="card-element">
                        <input id="cardNumber" className="input-field" name="cardNumber" type="text" placeholder={t("Card Number")} required value={cardNumber} onChange={e => {
                                const val =
                                e.target.value
                                  .replace(/\s|[^0-9]+/g, "")
                                  .match(/.{1,4}/g)
                                  ?.join(" ") ?? "";
                              setCardNumber(val);
                        }} />
  
                        <div style={{marginTop:0, marbinBottom:0}} className="row">
                          <div style={{marginTop:0, marbinBottom:0}} className="col-sm-6">
                            <select className="input-field" name="cardMonth" required value={cardMonth} onChange={e => setCardMonth(e.target.value)}>
                              <option value="01">01</option>
                              <option value="02">02</option>
                              <option value="03">03</option>
                              <option value="04">04</option>
                              <option value="05">05</option>
                              <option value="06">06</option>
                              <option value="07">07</option>
                              <option value="08">08</option>
                              <option value="09">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </select>
                          
                          </div>
                          <div style={{marginTop:0, marbinBottom:0}} className="col-sm-6">
                            <select className="input-field" name="cardYear" required value={cardYear} onChange={e => setCardYear(e.target.value)}>
                              <option value="23">2023</option>
                              <option value="24">2024</option>
                              <option value="25">2025</option>
                              <option value="26">2026</option>
                              <option value="27">2027</option>
                              <option value="28">2028</option>
                              <option value="29">2029</option>
                              <option value="30">2030</option>
                            </select>
                          </div>
                        </div>
                        <input id="cardCvv" className="input-field" name="cardCvv" type="text" placeholder={t("CVC")} required value={cardCvv} onChange={e => setCardCvv(e.target.value)} />
                      </div>
                    ) : null}
  
                    <div id="card-errors" role="alert">{cardErrors}</div>
  
  
                    {/* TODO: StockDads only */}
                    {/* <select value={marketing}  onChange={(e) => {setMarketing(e.target.value)}} name="marketing" class="input-field"><option selected disabled value="n/a">Where did you hear from us?</option><option value="Facebook">Facebook</option><option value="Stocks and Sandals Podcast">Stocks and Sandals Podcast</option><option value="TikTok">TikTok</option><option value="Instagram">Instagram</option><option value="YouTube">YouTube</option><option value="Twitter">Twitter</option><option value="StockTwits">StockTwits</option><option value="Search engine (Google, Yahoo, etc.)">Search engine (Google, Yahoo, etc.)</option><option value="Referred by a friend or colleague">Referred by a friend or colleague</option><option value="Blog or publication">Blog or publication</option><option value="TV or internet advertisement">TV or internet advertisement</option><option value="Business card">Business card</option><option value="Other">Other</option></select> */}
  
                    
                    
                    {data.useAuthorizeNet ? (
                      // Authorize.net
                      <div className="input-group mb-3">
                        <input id="coupon" className="form-control" name="coupon" type="text" placeholder={t("Referral Code")} value={coupon} onChange={e => setCoupon(e.target.value)}/>
                      </div>
                    ) : (
                      // Stripe
                      <div className="input-group mb-3">
                        <input id="coupon" className="form-control" name="coupon" type="text" placeholder={t("Coupon Code or Referral Code")} value={coupon} onChange={e => setCoupon(e.target.value)}/>
                        <div className="input-group-prepend">
                          <span id="apply-coupon" style={{ cursor: "pointer" }} className="input-group-text" onClick={() => handleCouponClick()}>{t('Apply')}</span>
                        </div>
                      </div>
                    )}
  
  
                    <div id="agreer">
                      <input id="checkmark" type="checkbox" name="terms" value="0" required onClick={() => {
                        if (checkmark == 0) {
                          setcheckmark(1);
                        } else {
                          setcheckmark(0);
                        }
                      }} />
                      <span style={{marginLeft:10}}id="agreement">{t('By clicking this box and subscribing you are agreeing to our')} <br /><a target="_blank" href={data.terms}>{t('Terms of Service')}</a></span>
                    </div>
                    <input disabled={buttonDisabled} style={{opacity: buttonDisabled ? 0.7 : 1, cursor: buttonDisabled ? "no-drop" : "pointer"}} className="input-field" id="checkout-submit" type="submit" value={buttonDisabled ? "..." : t("Subscribe")} />
                    
    
  
                    <div style={{display:'flex',justifyContent:'center'}}>
                      <a className="badge"  target="_blank" href="https://stripe.partners/directory/ternary-developers"><img  style={{marginTop:30,width:120}} src={stripeBadge}/></a>
                    </div>
                  </div>

                  {
                    !free ? (
                      <div className="col-lg-4">
                        <h2>{t('Order Summary')}</h2>
                        <div style={{ marginTop: "30px" }} className="row">
                          <div style={{ marginBottom: "35px" }} className="col-12">
                            <h5 id="product-name">{name}</h5>
                          </div>
                          <div className="col-6 left subtotal-row">
                            <p>{t('Subtotal')}</p>
                          </div>
                          <div className="col-6 right subtotal-row">
                            <p id="subtotal">{price}</p>
                          </div>
      
      
                          {tax > 0 ? (
                            <>
                            <div className="col-6 left subtotal-row">
                              <p>{t('Tax rate')}</p>
                            </div>
                            <div className="col-6 right subtotal-row">
                              <p>{tax}%</p>
                          </div>
                            </>
                          ) : null }
      
      
                          <div style={{ textAlign: "left" }} className="col-12">
                            <div id="coupon-alert" className={couponColor}>{couponAlert}</div>
                          </div>
                          {
                            trialDays > 0 ? (
                              <div style={{ textAlign: "left" }} className="col-12">
                                <div id="trial-alert" className={"green"}>{trialDays} {t('Free Trial Days Applied')}</div>
                              </div>
                            ) : (null)
                          }
                        </div>
                        <hr />
                        <div style={{ marginTop: "30px" }} className="row">
                          <div className="col-7 left">
                            <p><strong>{t("Due Today")}</strong></p>
                          </div>
                          <div className="col-5 right">
                            <p><strong id="total">{totalPrice}</strong></p>
                          </div>
                        </div>
                          
                        {lifetime === false ? (
                          <div style={{ marginTop: "0px" }} className="row">
                            <div className="col-7 left">
                              <p><strong>{t('Next Invoice')}</strong></p>
                            </div>
                            <div className="col-5 right">
      
                              {
                                trialDays > 0 ? (
                                  <p><strong id="subscription-total">{totalPrice}</strong></p>
                                )
                                : (
                                  <p><strong id="subscription-total">{totalSubPrice}</strong></p>
                                )
                              }
      
                            </div>
                        </div>
                        ) : null}
                        <div style={{ marginTop: "55px" }} className="row logo-row">
                          <div className="col-12">
                            <img className={"logo"} src={data.logo} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-lg-4">
                        <h2>{t('Order Summary')}</h2>
                        <div style={{ marginTop: "30px" }} className="row">
                          <div style={{ marginBottom: "35px" }} className="col-12">
                            <h5 id="product-name">{name}</h5>

    
                            <ul className="info">
                              {
                                highlightFeatures.map((feature, index) => {
                                  return (
                                    <li key={`feature-${index}`}>{feature}</li>
                                  )
                                })
                              }
                            </ul>

                            {
                              highlightFeatures.length > 0 && (
                                <hr />
                              )
                            }
                            <div style={{ marginTop: "30px" }} className="row">
                              <div className="col-7 left">
                                <p><strong>{t("Due Today")}</strong></p>
                              </div>
                              <div className="col-5 right">
                                <p><strong id="total">FREE</strong></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }

                </div>
              </div>
            </form>
          ) : (
            <LoggedOut 
            coupon={coupon}
            discount={discount}
            price={price}
            total={totalPrice}
            free={free}
            highlightFeatures={highlightFeatures}
            products={data.products} name={data.name} logo={data.logo} redirect={loginRedirect}></LoggedOut>
          )}

          <div style={{textAlign:"center",width:'100%', marginTop:-80, height:30}}>
            <a target="_blank" href="https://ternarydev.com/" className="watermark">Powered by <span style={{fontWeight:700}}>Ternary</span></a>
          </div>
        </div> :
        <div>
          <div id="navbar">
          </div>

          <div>
            <CircleLoader />
          </div>

        </div>
      }
    </div>)
}

export default Register;
