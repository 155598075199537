import React from "react";
import { motion } from "framer-motion";

const containerStyle = {
  position: "relative",
  display:"flex",
  flexDirection:"row",
  height:60,
  paddingTop:30,
  width:"100%",
  boxSizing: "border-box",
  justifyContent:"center"
};

const circleStyle = {
  display: "block",
  width: "2rem",
  height: "2rem",
  border: "0.3rem solid #c8c8c8",
  borderTop: "0.3rem solid #FFF",
  borderRadius: "50%",
  boxSizing: "border-box"
};

const spinTransition = {
  loop: Infinity,
  ease: "linear",
  duration: 1
};

export default function CircleLoader() {
  return (
    <div style={containerStyle}>
      <motion.span
        style={circleStyle}
        animate={{ rotate: 360 }}
        transition={spinTransition}
      />
    </div>
  );
}
